/* eslint-disable max-len */
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { FiExternalLink } from 'react-icons/fi';
import { useProjectDetailsPage } from '@modules/projects/components/project-details/ProjectDetailPage';
import {
  checkOrgHistory,
  getOrganizationChainToTop,
  renderDateRange,
} from '@utils';
import { useTranslation } from 'react-i18next';
import { filesize } from 'filesize';
import uniqBy from 'lodash/uniqBy';
import { gql } from '@apollo/client';
import { useQuery } from '@lib/hooks';
import { getGeneralProjectInfos } from '@modules/projects/data';
import Link from '@modules/projects/components/project-details/fragments/Link';
import Organization from '@modules/projects/components/project-details/fragments/Organization';
import OrganizationChain from '@modules/projects/components/project-details/fragments/OrganizationChain';
import ResponsiveImage from '@components/ResponsiveImage';

export default function ProjectInformation(props) {
  const { project, project2 } = props;
  const { t, i18n } = useTranslation();
  const { openProjectDetailsPage } = useProjectDetailsPage();

  const { data } = useQuery(GET_DATA, {
    variables: {
      references: ['sector'],
    },
  });

  const handleOpenProject = (id) => () => {
    openProjectDetailsPage(id, 'list');
  };

  return (
    <>
      {project.attributes.isShowNotesForSubProjects && (
        <Alert severity="info" sx={{ mb: 3 }} variant="filled">
          <strong>{t('modules.projects.components.projectDetails.views.ProjectInformation.Alert.title')}</strong>
          {' '}
          {t('modules.projects.components.projectDetails.views.ProjectInformation.Alert.content')}
        </Alert>
      )}
      {project.attributes.projectType === 'jointProject' ? (
        <ProjectSubProjects
          i18n={i18n}
          project={project}
          t={t}
          tagItems={data?.tagItems.data}
        />
      ) : (
        <ProjectManagementSection
          i18n={i18n}
          project={project}
          t={t}
          tagItems={data?.tagItems.data}
        />
      )}
      <FundingSection
        i18n={i18n}
        onOpenProject={handleOpenProject}
        project={project}
        project2={project2}
        t={t}
        tagItems={data?.tagItems.data}
      />
    </>
  );
}

function ProjectSubProjects({
  project, t, i18n, tagItems,
}) {
  const subProjects = project.attributes.projects.data;

  return (
    <>
      <Typography gutterBottom sx={{ ml: 1 }} variant="h6">
        Projektbeteiligte
      </Typography>
      {subProjects.map((subProject, index) => (
        <Paper key={subProject.id} sx={{ p: 1, mt: index > 0 ? 5 : 0 }} variant="outlined">
          <SubProject
            i18n={i18n}
            project={subProject}
            t={t}
            tagItems={tagItems}
          />
        </Paper>
      ))}
      <Box mb={5} mt={3}>
        <Button
          disabled
          onClick={() => {
            alert('Feature noch nicht verfügbar.');
          }}
          size="small"
          variant="contained"
        >
          Teilprojekte in Ergebnisliste anzeigen
        </Button>
      </Box>
    </>
  );
}

function ProjectManagementSection({ t, project, tagItems }) {
  const executingOrgs = [project.attributes.primary_executing_org?.data].filter(Boolean);
  const primaryExecutingOrg = executingOrgs.shift();
  const finalBeneficiaryOrg = project.attributes.final_beneficiary_org?.data;

  return (
    <>
      <Typography gutterBottom sx={{ ml: 1 }} variant="h6">
        {t('modules.projects.components.projectDetails.views.ProjectInformation.projectSupport')}
      </Typography>
      <Paper sx={{ p: 1, mb: 5 }} variant="outlined">
        <Box mb={5}>
          <OrganizationLinkTitle>
            {t('modules.projects.components.projectDetails.views.ProjectInformation.executingAgency')}
          </OrganizationLinkTitle>
          {primaryExecutingOrg
          && primaryExecutingOrg.attributes.publicationState === 'published'
            ? (
              <Organization
                organization={primaryExecutingOrg}
                tagItems={tagItems}
              />
            ) : (
              <Typography variant="body2">
                {t('modules.projects.components.projectDetails.views.ProjectInformation.notSpecified')}
              </Typography>
            )}
        </Box>
        {executingOrgs.length > 0 && (
          <Box mb={5}>
            <OrganizationLinkTitle>
              {t('modules.projects.components.projectDetails.views.ProjectInformation.otherExecutingAgencies')}
            </OrganizationLinkTitle>
            {executingOrgs.map((org) => (
              <Box key={org.id} mb={2}>
                <Organization
                  noLogo
                  organization={org}
                  secondary
                  tagItems={tagItems}
                />
              </Box>
            ))}
          </Box>
        )}
        <Box>
          <OrganizationLinkTitle>
            {t('modules.projects.components.projectDetails.views.ProjectInformation.beneficiary')}
          </OrganizationLinkTitle>
          {finalBeneficiaryOrg
          && finalBeneficiaryOrg.attributes.publicationState === 'published'
            ? (
              <Organization
                noLogo
                organization={finalBeneficiaryOrg}
                tagItems={tagItems}
              />
            ) : (
              <Typography variant="body2">
                {t('modules.projects.components.projectDetails.views.ProjectInformation.notSpecified')}
              </Typography>
            )}
        </Box>
      </Paper>
    </>
  );
}

function FundingSection({
  project, project2, t, i18n, tagItems, onOpenProject,
}) {
  const fundingOrgs = getFundingOrgs(project);
  const projectSponsorOrgs = getProjectSponsorOrgs(project);

  let fundingMeasure = project.attributes.funding_measure?.data;

  if (project.attributes.projectType === 'subProject'
    && project.attributes.parent_project?.data) {
    fundingMeasure = project.attributes.parent_project?.data?.attributes.funding_measure?.data;
  }

  const logo = fundingMeasure?.attributes.tag_item_files.data
    .find((f) => f.attributes.isPublic && f.attributes.type === 'logo');

  return (
    <>
      <Typography gutterBottom sx={{ ml: 1 }} variant="h6">
        {t('modules.projects.components.projectDetails.views.ProjectInformation.promotion')}
      </Typography>
      <Paper sx={{ p: 1, mb: 5 }} variant="outlined">
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            {fundingOrgs[0]?.length > 0 && fundingOrgs[0][0].attributes.logo_funding_partner_de?.data && (
              <ResponsiveImage
                maxHeight={200}
                src={
                  fundingOrgs[0][0].attributes.logo_funding_partner_de.data.attributes.formats
                    ?.small?.url
                  ?? fundingOrgs[0][0].attributes.logo_funding_partner_de.data.attributes.formats
                    ?.medium?.url
                  ?? fundingOrgs[0][0].attributes.logo_funding_partner_de.data.attributes.formats
                    ?.large?.url
                  ?? fundingOrgs[0][0].attributes.logo_funding_partner_de.data.attributes.url
                }
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {project2.type.value !== 'jointProject' && (
              <Box>
                <Typography variant="body2">
                  <strong>
                    {t('modules.projects.components.projectDetails.views.ProjectInformation.fundingCode')}
                  </strong>
                  {' '}
                  {project.attributes.referenceId || t('modules.projects.components.projectDetails.views.ProjectInformation.notSpecified')}
                </Typography>
              </Box>
            )}
            <Box display="flex">
              <Typography sx={{ mr: 1 }} variant="body2">
                <strong>
                  {t('modules.projects.components.projectDetails.views.ProjectInformation.sponsor')}
                </strong>
              </Typography>
              <Box>
                {fundingOrgs.length > 0 ? (
                  <OrganizationChain chain={fundingOrgs} />
                ) : (
                  <Typography color="text.disabled" variant="body2">
                    {t('modules.projects.components.projectDetails.views.ProjectInformation.notSpecified')}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box display="flex">
              <Typography sx={{ mr: 1 }} variant="body2">
                <strong>
                  {t('modules.projects.components.projectDetails.views.ProjectInformation.projectSponsor')}
                </strong>
              </Typography>
              <Box>
                {projectSponsorOrgs.length > 0 ? (
                  <OrganizationChain chain={projectSponsorOrgs} />
                ) : (
                  <Typography color="text.disabled" variant="body2">
                    {t('modules.projects.components.projectDetails.views.ProjectInformation.notSpecified')}
                  </Typography>
                )}
              </Box>
            </Box>
            <Paper sx={{ mt: 2, p: 1, bgcolor: 'background.default' }} variant="outlined">
              <Box display="flex">
                <Typography sx={{ mr: 1 }} variant="body2">
                  <strong>
                    {t('modules.projects.components.projectDetails.views.ProjectInformation.performancePlanSystem')}
                  </strong>
                </Typography>
                <Typography variant="body2">
                  {project.attributes.performancePlanSystem || t('modules.projects.components.projectDetails.views.ProjectInformation.notSpecified')}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography sx={{ mr: 1 }} variant="body2">
                  <strong>
                    {t('modules.projects.components.projectDetails.views.ProjectInformation.promotionProfile')}
                  </strong>
                </Typography>
                <Typography variant="body2">
                  {project.attributes.fundingProfile || t('modules.projects.components.projectDetails.views.ProjectInformation.notSpecified')}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography sx={{ mr: 1 }} variant="body2">
                  <strong>
                    {t('modules.projects.components.projectDetails.views.ProjectInformation.fundingType')}
                  </strong>
                </Typography>
                <Typography variant="body2">
                  {project.attributes.fundingType || t('modules.projects.components.projectDetails.views.ProjectInformation.notSpecified')}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        {project.attributes.projectType === 'subProject'
          && project.attributes.parent_project?.data && (
            <>
              <Box my={3}>
                <Divider />
              </Box>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography sx={{ mb: 1 }} variant="subtitle2">
                    &nbsp;
                  </Typography>
                  {project.attributes.parent_project.data.attributes.logo_default?.data && (
                    <ResponsiveImage
                      src={
                        project.attributes.parent_project.data.attributes.logo_default
                          .data.attributes.formats?.small?.url
                        ?? project.attributes.parent_project.data.attributes.logo_default
                          .data.attributes.formats?.medium?.url
                        ?? project.attributes.parent_project.data.attributes.logo_default
                          .data.attributes.formats?.large?.url
                        ?? project.attributes.parent_project.data.attributes.logo_default
                          .data.attributes.url
                      }
                    />
                  )}
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography variant="subtitle2">
                    Dieses Projekt ist Teil des Verbundprojekts
                  </Typography>
                  <Button
                    onClick={onOpenProject(project.attributes.parent_project.data.id)}
                    startIcon={<FiExternalLink />}
                  >
                    <Box alignItems="flex-start" display="flex" flexDirection="column">
                      <Typography
                        color="text.primary"
                        sx={{ fontWeight: 'bold' }}
                        textAlign="left"
                        variant="subtitle2"
                      >
                        {project.attributes.parent_project.data.attributes.abbreviation}
                      </Typography>
                      <Typography
                        color="text.primary"
                        textAlign="left"
                        variant="body2"
                      >
                        {project.attributes.parent_project.data.attributes.name_de}
                      </Typography>
                    </Box>
                  </Button>
                </Grid>
              </Grid>
              {project.attributes.parent_project.data.attributes.projects.data.length > 0
                && (
                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item md={3} xs={12}>
                      <Typography variant="subtitle2">
                        Weitere Teilprojekte des Verbundvorhabens
                      </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                      {project.attributes.parent_project?.data?.attributes.projects.data
                        .map((subProject, index) => (
                          <Paper key={subProject.id} sx={{ p: 1, mt: index > 0 ? 3 : 0 }} variant="outlined">
                            <SubProject
                              i18n={i18n}
                              project={subProject}
                              t={t}
                              tagItems={tagItems}
                            />
                          </Paper>
                        ))}
                      <Box mt={3}>
                        <Button
                          disabled
                          onClick={() => {
                            alert('Feature noch nicht verfügbar.');
                          }}
                          size="small"
                          variant="contained"
                        >
                          Teilprojekte in Ergebnisliste anzeigen
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                )}
            </>
        )}
        {fundingMeasure && (
          <>
            <Box my={3}>
              <Divider />
            </Box>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <Typography variant="subtitle2">
                  {t('modules.projects.components.projectDetails.views.ProjectInformation.partOfTheFundingInitiative')}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                {logo && (
                  <ResponsiveImage
                    src={
                      logo.attributes.file.data.attributes.formats?.small?.url
                      ?? logo.attributes.file.data.attributes.formats?.medium?.url
                      ?? logo.attributes.file.data.attributes.formats?.large?.url
                      ?? logo.attributes.file.data.attributes.url
                    }
                    sx={{ maxWidth: 300 }}
                  />
                )}
                {fundingMeasure.attributes.tag_item_abbreviations.data.length > 0 && (
                  <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
                    {fundingMeasure.attributes.tag_item_abbreviations.data[0].attributes.name_de}
                  </Typography>
                )}
                <Typography variant="body2">
                  {fundingMeasure.attributes.name_de}
                </Typography>
                <Box mb={3}>
                  {fundingMeasure.attributes.tag_item_links.data.map((link) => (
                    <Box key={link.id}>
                      <Link
                        href={link.attributes.url}
                        name={link.attributes.name_de}
                      />
                    </Box>
                  ))}
                </Box>
                <Box mb={3}>
                  {fundingMeasure.attributes.tag_item_files.data
                    .filter((file) => file.attributes.isPublic && file.id !== logo?.id)
                    .map((file) => (
                      <Box key={file.id}>
                        <Link
                          href={file.attributes.file.data.attributes.url}
                          info={`${file.attributes.title} (${filesize(file.attributes.file.data.attributes.size)})`}
                          name={file.attributes.file.data.attributes.name}
                        />
                      </Box>
                    ))}
                </Box>
                <Box mb={5} mt={3}>
                  <Button
                    disabled
                    onClick={() => {
                      alert('Feature noch nicht verfügbar.');
                    }}
                    size="small"
                    variant="contained"
                  >
                    Alle Projekte der Förderinitiative in Ergebnisliste anzeigen
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </>
  );
}

function SubProject({
  project, t, i18n, tagItems,
}) {
  const projectInfos = getGeneralProjectInfos(project, {
    t,
    i18n,
  });
  const executingOrgs = [project.attributes.primary_executing_org?.data].filter(Boolean);
  const primaryExecutingOrg = executingOrgs.shift();

  return (
    <>
      <Box mb={1}>
        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
          {projectInfos.type.label1}
        </Typography>
        <Typography variant="body2">
          {project.attributes.name_de}
        </Typography>
      </Box>
      <Box display="flex">
        <Typography sx={{ mr: 1 }} variant="subtitle2">
          Laufzeit:
        </Typography>
        <Typography variant="body2">
          {renderDateRange(
            t,
            i18n,
            projectInfos.projectStart,
            projectInfos.projectEnd,
          )}
        </Typography>
      </Box>
      <Box display="flex" mb={2}>
        <Typography sx={{ mr: 1 }} variant="subtitle2">
          Förderkennzeichen:
        </Typography>
        <Typography variant="body2">
          {project.attributes.referenceId}
        </Typography>
      </Box>
      <Box>
        <OrganizationLinkTitle>
          Ausführende Stelle
        </OrganizationLinkTitle>
        {primaryExecutingOrg
        && primaryExecutingOrg.attributes.publicationState === 'published'
          ? (
            <Organization
              organization={primaryExecutingOrg}
              tagItems={tagItems}
            />
          ) : (
            <Typography variant="body2">
              {t('modules.projects.components.projectDetails.views.ProjectInformation.notSpecified')}
            </Typography>
          )}
      </Box>
      {executingOrgs.length > 0 && (
        <Box mt={2}>
          <OrganizationLinkTitle>
            Weitere ausführende Stellen
          </OrganizationLinkTitle>
          {executingOrgs.map((org) => (
            <Box key={org.id} mb={2} ml={1}>
              <Organization
                noLogo
                organization={org}
                secondary
                tagItems={tagItems}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

function OrganizationLinkTitle({ children }) {
  return (
    <Box>
      <Typography sx={{ fontWeight: 'bold' }} variant="caption">
        {children}
      </Typography>
      <Divider sx={{ mb: 1 }} />
    </Box>
  );
}

ProjectInformation.tabFilter = (project) => (true);

function getProjectManagerOrgs(p) {
  let chains = [];
  const compare = (org) => org && org.attributes.publicationState === 'published';

  if (p.attributes.projectType === 'jointProject') {
    chains = [
      p,
      ...p.attributes.projects.data,
    ].map(
      (subProject) => getOrganizationChainToTop(
        subProject.attributes.project_manager_org?.data,
        compare,
      ).map((org) => checkOrgHistory(subProject, org)),
    );
  } else {
    const orgs = getOrganizationChainToTop(p.attributes.project_manager_org?.data, compare);

    if (orgs.length > 0) {
      chains.push(orgs.map((org) => checkOrgHistory(p, org)));
    }
  }

  chains = chains.filter((chain) => chain.length > 0);
  chains = uniqBy(chains, (chain) => chain.map((org) => org.id).join(''));

  return chains;
}

function getProjectSponsorOrgs(p) {
  let chains = [];
  const compare = (org) => org && org.attributes.publicationState === 'published';

  if (p.attributes.projectType === 'jointProject') {
    chains = [
      p,
      ...p.attributes.projects.data,
    ].map(
      (subProject) => getOrganizationChainToTop(
        subProject.attributes.project_sponsor_org?.data,
        compare,
      ).map((org) => checkOrgHistory(subProject, org)),
    );
  } else {
    const orgs = getOrganizationChainToTop(p.attributes.project_sponsor_org?.data, compare);

    if (orgs.length > 0) {
      chains.push(orgs.map((org) => checkOrgHistory(p, org)));
    }
  }

  chains = chains.filter((chain) => chain.length > 0);
  chains = uniqBy(chains, (chain) => chain.map((org) => org.id).join(''));

  return chains;
}

function getFundingOrgs(p) {
  let chains = [];
  const compare = (org) => org?.attributes.publicationState === 'published';

  if (p.attributes.projectType === 'jointProject') {
    chains = [
      p,
      ...p.attributes.projects.data,
    ].map(
      (subProject) => getOrganizationChainToTop(
        subProject.attributes.funding_org?.data,
        compare,
      ).map((org) => checkOrgHistory(subProject, org)),
    );
  } else {
    const orgs = getOrganizationChainToTop(p.attributes.funding_org?.data, compare);

    if (orgs.length > 0) {
      chains.push(orgs.map((org) => checkOrgHistory(p, org)));
    }
  }

  return uniqBy(chains.filter((o) => o.length > 0), (o) => o[0]?.[0]?.id);
}

const TAG_ITEM_ATTRIBUTES = `
name_de
name_en
index
referenceId
`;

function getGraphQlSchemaForTagItem(depth = 0) {
  if (depth >= 25) {
    return `
      id
      attributes {
        ${TAG_ITEM_ATTRIBUTES}
      }
    `;
  }

  return `
    id
    attributes {
      ${TAG_ITEM_ATTRIBUTES}
      child_items(
        sort: ["index", "name_de", "id"],
        pagination: { limit: 100 }
      ) {
        data {
          ${getGraphQlSchemaForTagItem(depth + 1)}
        }
      }
    }
  `;
}

const GET_DATA = gql`
  query TagRoot($references: [String]!) {
    tagItems(
      sort: ["index", "name_de"],
      filters: {
        referenceId: {in: $references}
      }
    ) {
      data {
        ${getGraphQlSchemaForTagItem()}
      }
    }
  }
`;
