/* eslint-disable no-param-reassign */
import { Decimal } from 'decimal.js';

const MONEY_CONVERT_FACTOR = new Decimal(100);

export function convertInputToMoney(value) {
  if (!value) return value;

  value = value.replaceAll('.', '').replace(',', '.');

  const d = new Decimal(value);

  if (d.isNaN()) return value;

  return Math.trunc(d.mul(MONEY_CONVERT_FACTOR).toNumber());
}

export function convertMoneyToInput(value) {
  if (!value) return value;

  const d = new Decimal(value);

  if (d.isNaN()) return value;

  value = d.div(MONEY_CONVERT_FACTOR).toFixed(2);

  return value.replace('.', ',');
}

export function money(yup) {
  return yup.string().trim().matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(\.\d{3})*)|\d+)?(,\d{1,2})?$/);
}

export function renderMoneyValue(value, options = {}) {
  if (!value) return value;

  const d = new Decimal(value);

  if (d.isNaN()) return value;

  return d.div(MONEY_CONVERT_FACTOR)
    .toNumber()
    .toLocaleString('de-DE', {
      ...options,
      style: 'currency',
      currency: 'EUR',
    });
}
