export function getAddressStateWithCountry(item) {
  if (!item) {
    return null;
  }

  const state = item.location?.state;
  const country = item.location?.country;
  const parts = [];

  if (state) {
    parts.push(state);
  }

  if (country && !DO_NOT_SHOW_COUNTRY_LIST.includes(country.toLowerCase())) {
    parts.push(country);
  }

  return parts.join(', ');
}

export function getAddressCountry(item) {
  if (!item) {
    return null;
  }

  const country = item.location?.country
    ?? item.attributes?.addressCountry
    ?? item.addressCountry;

  if (country && !DO_NOT_SHOW_COUNTRY_LIST.includes(country.toLowerCase())) {
    return country;
  }

  return null;
}

const DO_NOT_SHOW_COUNTRY_LIST = [
  'germany',
  'deutschland',
];
