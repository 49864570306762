import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Organization from '@modules/projects/components/project-details/fragments/Organization';
import Contact from '@modules/projects/components/project-details/fragments/Contact';
import {
  checkOrgHistory,
  getOrganizationChainToTop,
} from '@utils';
import uniqBy from 'lodash/uniqBy';
import ContactDialog from '@modules/projects/components/project-details/ContactDialog';
import { useState } from 'react';

export default function ProjectContact(props) {
  const { project } = props;
  const { t, i18n } = useTranslation();
  const [openContactDialog, setOpenContactDialog] = useState({ isOpen: false });
  const projectManagerOrgs = getProjectManagerOrgs(project);

  const handleOpenContactForm = (contact) => {
    setOpenContactDialog({ isOpen: true, contact });
  };

  return (
    <>
      <ContactsContainer
        i18n={i18n}
        onOpenContactForm={handleOpenContactForm}
        organization={projectManagerOrgs[0]?.[0]}
        project={project}
        t={t}
        title={getProjectManagementTitle(project)}
      />
      <ContactDialog
        contact={openContactDialog.contact}
        isOpen={openContactDialog.isOpen}
        onClose={() => setOpenContactDialog({ isOpen: false })}
      />
    </>
  );
}

ProjectContact.tabFilter = () => true;

function ContactsContainer(props) {
  const {
    title,
    project,
    organization,
    onOpenContactForm,
  } = props;

  const projectManager = getContact(project.attributes.project_manager?.data);
  const alternativeContact = getContact(project.attributes.alternative_contact?.data);
  const pressContact = getContact(project.attributes.press_contact?.data);

  return (
    <Grid container spacing={3}>
      <Grid item md={3} xs={12}>
        <Typography gutterBottom variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item md={9} xs={12}>
        <Box mb={5}>
          {organization ? (
            <Organization
              organization={organization}
            />
          ) : (
            <Typography color="text.disabled" variant="body2">
              keine Angaben
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item md={3} xs={12}>
        <Typography gutterBottom variant="subtitle2">
          {project.attributes.projectType === 'jointProject' ? 'Verbundprojektkoordinator*in' : null}
          {project.attributes.projectType === 'subProject' ? 'Teilprojektleiter*in' : null}
          {project.attributes.projectType === 'individualProject' ? 'Projektleiter*in' : null}
        </Typography>
      </Grid>
      <Grid item md={9} xs={12}>
        <Box mb={5}>
          {projectManager ? (
            <Contact
              contact={projectManager}
              onOpenContactForm={onOpenContactForm}
            />
          ) : (
            <Typography color="text.disabled" variant="body2">
              keine Angaben
            </Typography>
          )}
        </Box>
      </Grid>
      {alternativeContact && (
        <>
          <Grid item md={3} xs={12}>
            <Typography gutterBottom variant="subtitle2">
              alternativer Kontakt
            </Typography>
          </Grid>
          <Grid item md={9} xs={12}>
            <Box mb={5}>
              <Contact
                contact={alternativeContact}
                onOpenContactForm={onOpenContactForm}
              />
            </Box>
          </Grid>
        </>
      )}
      {pressContact && (
        <>
          <Grid item md={3} xs={12}>
            <Typography gutterBottom variant="subtitle2">
              Pressekontakt
            </Typography>
          </Grid>
          <Grid item md={9} xs={12}>
            <Box mb={5}>
              <Contact
                contact={pressContact}
                onOpenContactForm={onOpenContactForm}
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
}

function getProjectManagementTitle(project) {
  if (project.attributes.projectType === 'jointProject') {
    return 'Verbundprojektkoordination';
  }
  if (project.attributes.projectType === 'subProject') {
    return 'Teilprojektleitung';
  }
  return 'Projektleitung';
}

function getProjectManagerOrgs(p) {
  let chains = [];
  const compare = (org) => org && org.attributes.publicationState === 'published';

  if (p.attributes.projectType === 'jointProject') {
    chains = [
      p,
      ...p.attributes.projects.data,
    ].map(
      (subProject) => getOrganizationChainToTop(
        subProject.attributes.project_manager_org?.data,
        compare,
      ).map((org) => checkOrgHistory(subProject, org)),
    );
  } else {
    const orgs = getOrganizationChainToTop(p.attributes.project_manager_org?.data, compare);

    if (orgs.length > 0) {
      chains.push(orgs.map((org) => checkOrgHistory(p, org)));
    }
  }

  chains = chains.filter((chain) => chain.length > 0);
  chains = uniqBy(chains, (chain) => chain.map((org) => org.id).join(''));

  return chains;
}

function getContact(contact) {
  if (!contact || contact.attributes.isDeleted || ![
    'publishContactInformation',
    'published',
  ].includes(contact.attributes.publicationState)) {
    return null;
  }

  return contact;
}
