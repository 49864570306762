import NextImage from 'next/image';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export default function ResponsiveImage(props) {
  const {
    src,
    alt,
    sx,
    maxHeight,
    ...otherProps
  } = props;

  return (
    <Box
      {...otherProps}
      sx={{
        ...sx,
        p: 0.5,
        overflow: 'hidden',
        bgcolor: 'background.paper',
        borderRadius: '4px',
      }}
    >
      <NextImage
        alt={alt || ''}
        height={300}
        loading="lazy"
        sizes="100vw"
        src={src}
        style={{
          display: 'block',
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          maxHeight,
        }}
        width={500}
      />
    </Box>
  );
}

ResponsiveImage.propTypes = {
  alt: PropTypes.string,
  maxHeight: PropTypes.number,
};

ResponsiveImage.defaultProps = {
  alt: '',
  maxHeight: 100,
};
