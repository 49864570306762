import { DateTime } from 'luxon';

export function renderDateRange(t, i18n, start, end) {
  const startDate = start
    ? DateTime.fromISO(start)
      .setLocale(i18n.language)
      .toFormat(t('shared.format.date.short'))
    : null;
  const endDate = end
    ? DateTime.fromISO(end)
      .setLocale(i18n.language)
      .toFormat(t('shared.format.date.short'))
    : null;

  if (!startDate && !endDate) return '-';

  if (startDate && !endDate) return `seit ${startDate}`;

  if (!startDate && endDate) return `bis ${endDate}`;

  return `${startDate} - ${endDate}`;
}
