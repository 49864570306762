import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';

export function findParentOrganization(
  organization,
  compare = findParentOrganizationDefaultFilter,
) {
  if (compare(organization)) {
    return organization;
  }

  if (Array.isArray(organization?.attributes.parent_organizations?.data)
    && !organization?.attributes.isParentOrgHidden) {
    // eslint-disable-next-line no-restricted-syntax
    for (const parentOrganization of organization.attributes.parent_organizations.data) {
      const result = findParentOrganization(parentOrganization, compare);

      if (result) {
        return result;
      }
    }
  }

  return null;
}

export function getOrganizationChainToTop(
  organization,
  compare = findParentOrganizationDefaultFilter,
  toBottom = false,
) {
  let result = [];

  if (!organization?.attributes) {
    console.log(organization);
  }

  if (Array.isArray(organization?.attributes.parent_organizations?.data)
    && !organization?.attributes.isParentOrgHidden) {
    // eslint-disable-next-line no-restricted-syntax
    for (const parentOrganization of organization.attributes.parent_organizations.data) {
      result = getOrganizationChainToTop(parentOrganization, compare);
    }
  }

  if (compare(organization)) {
    result.push(organization);
  }

  if (toBottom && Array.isArray(organization.attributes.organizations?.data)) {
    result.push(...getOrganizationChainToBottom(organization, compare, true));
  }

  return result;
}

export function getOrganizationChainToBottom(
  organization,
  compare = findParentOrganizationDefaultFilter,
  onlyChildren = false,
) {
  const result = [];

  if (!organization?.attributes) {
    console.log(organization);
  }

  if (Array.isArray(organization?.attributes.organizations?.data)) {
    result.push(
      ...organization.attributes.organizations.data
        .flatMap((childOrganization) => getOrganizationChainToBottom(childOrganization, compare)),
    );
  }

  if (!onlyChildren && compare(organization)) {
    result.push(organization);
  }

  return result;
}

function findParentOrganizationDefaultFilter(org) {
  return Boolean(org);
}

export function checkOrgHistory(project, org) {
  if (!org) {
    return null;
  }

  const { projectEnd } = project.attributes;
  const { isHistoryVisible } = org.attributes;
  const historyItems = orderBy(
    org.attributes.organization_histories?.data ?? [],
    ['attributes.date'],
    ['asc'],
  );

  if (!projectEnd || !isHistoryVisible) {
    return org;
  }

  const end = DateTime.fromISO(projectEnd).startOf('day');

  // find history item with end date equal/after project end date
  const item = historyItems.find((historyItem) => {
    const renameDate = DateTime.fromISO(historyItem.attributes.date).startOf('day');
    return renameDate >= end;
  });

  if (item) {
    return {
      id: item.id,
      attributes: {
        abbreviation_de: item.attributes.abbreviation_de,
        abbreviation_en: item.attributes.abbreviation_en,
      },
    };
  }

  return org;
}

export function getOrganizationCategory(o) {
  if (![
    'company',
    'location',
    'department',
  ].includes(o.attributes.organizationType)) {
    return null;
  }

  const compare = (org) => org && org.attributes.publicationState === 'published'
    && !org.isHiddenInCompanyDatabase
    && org.attributes.numOfEmployees && (
    org.attributes.turnoverPerYear || org.attributes.balanceSheetTotal
  );
  const org = findParentOrganization(o, compare);

  if (!org) {
    return null;
  }

  const {
    numOfEmployees: empl,
    turnoverPerYear: turn,
    balanceSheetTotal: blnc,
  } = org.attributes;

  if (empl === 'upTo9' && (
    turn === 'upTo2Million'
    || blnc === 'upTo2Million')
  ) {
    return 'smallest';
  }

  if (
    ((empl === 'upTo9' || empl === 'upTo49') && (turn === 'upTo10Million' || blnc === 'upTo10Million'))
    || (empl === 'upTo49' && (
      turn === 'upTo2Million' || blnc === 'upTo2Million'
      || turn === 'upTo10Million' || blnc === 'upTo10Million'))
  ) {
    return 'small';
  }

  if (
    (
      (empl === 'upTo9' || empl === 'upTo49' || empl === 'upTo249')
      && (turn === 'upTo50Million' || blnc === 'upTo43Million'))
    || (empl === 'upTo249' && (
      turn === 'upTo2Million' || blnc === 'upTo2Million'
      || turn === 'upTo10Million' || blnc === 'upTo10Million'
      || turn === 'upTo50Million' || blnc === 'upTo43Million'))
  ) {
    return 'medium';
  }

  if (empl === 'over250' || turn === 'over50Million' || blnc === 'over43Million') {
    return 'large';
  }

  return null;
}
